<template>
    <span class="cs-button-container">
        <template v-if="['media','url'].includes(i.type) && ( i.target == '_blank' || i.target == '_self' )">
            <a class="cs-button" v-if="title.length" :href="i.url" :target="i.target">{{title}}</a>
            <a class="cs-button" v-else :href="i.url" :target="i.target">
                <slot></slot>
            </a>
        </template>
        <template v-if="i.target == '_download'">
            <a class="cs-button" v-if="title.length" :href="i.url" :download="title">{{title}}</a>
            <a class="cs-button" v-else :href="i.url" :download="title">
                <slot></slot>
            </a>
        </template>
        <template v-if="i.target == '_popup'">
            <span class="cs-button" v-if="title.length" @click="openPopup()" >
                {{title}}
            </span>
            <span class="cs-button" v-else @click="openPopup()" >
                <slot></slot>
            </span>
        </template>
        <div v-if="popupOpen" class="cs-popup-container" @click="closePopup($event,true)">
            <div class="cs-popup">
                <div class="cs-popup-close" @click="closePopup($event,false)">
                    <svg id="Layer_1" height="512" viewBox="0 0 48 48" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m28.39 24 18.7-18.7a3.09 3.09 0 0 0 .91-2.2 3 3 0 0 0 -.91-2.19 3 3 0 0 0 -2.19-.91 3.09 3.09 0 0 0 -2.2.91l-18.7 18.7-18.7-18.7a3.09 3.09 0 0 0 -2.2-.91 3 3 0 0 0 -2.19.91 3 3 0 0 0 -.91 2.19 3.09 3.09 0 0 0 .91 2.2l18.7 18.7-18.7 18.7a3.09 3.09 0 0 0 -.91 2.2 3 3 0 0 0 .91 2.19 3 3 0 0 0 2.19.91 3.09 3.09 0 0 0 2.2-.91l18.7-18.7 18.7 18.7a3.09 3.09 0 0 0 2.2.91 3.11 3.11 0 0 0 3.1-3.1 3.09 3.09 0 0 0 -.91-2.2z"/></svg>
                </div>
                <div class="cs-popup-content">
                    <template v-if="i.type == 'media'">
                        <CSMedia :data="i.url" />
                    </template>
                    <template v-else-if="i.type == 'embed'">
                        <div class="embed-component" v-html="i.url"></div>
                    </template>
                    <template v-else-if="i.type == 'calculation'">
                        <CSCalculation :id="i.url" />
                    </template>
                    <template v-else-if="i.type == 'form'">
                        <CSForm :id="i.url" />
                    </template>
                    <template v-else-if="i.type == 'table'">
                        <CSTable :id="i.url" />
                    </template>
                </div>
            </div>
        </div>
    </span>
</template>
<script>

export default {
    name: 'CSButton',
    components: {
        CSMedia: () => import("../components/CSMedia.vue"),
        CSCalculation: () => import("../components/CSCalculation.vue"),
        CSForm: () => import("../components/CSForm.vue"),
        CSTable: () => import("../components/CSTable.vue")
    },
    props: {
        data:{
            type:[Object, Array, String]
        },
        title: {
            type: String,
            default: ''
        }
    },
    data(){
        return {
            popupOpen:false
        }
    },
    computed: {
        i() {
            if(typeof this.data == 'object') {
                if(this.data.type == 'url' && this.data.url.charAt(0) != '/' && this.data.url.indexOf('http') != 0){
                    this.data.url = 'https://'+this.data.url
                }else if(this.data.type == 'media') this.data.url = '/media/'+this.data.url
                return this.data;
            }else{
                return {
                    type:'url',
                    url:this.data,
                    target:'_self'
                }
            }
        }
    },
    methods: {
        openPopup(){
            this.popupOpen = true;
        },
        closePopup(e,location){
            if((`${e.srcElement.className}` == 'cs-popup-container' && location) || !location) this.popupOpen = false;
        }
    },
    mounted(){}
}
</script>



<style lang="scss" scoped>
.cs-button{
    position: relative;
    display: block;
}
    
a{
    color: inherit;
    text-decoration: none;
}
.cs-popup-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    .cs-popup {
        width: 800px;
        max-width: 80%;
        height: 800px;
        max-height: 80%;
        padding: 20px;
        position:relative;
        .cs-popup-close{
            position: absolute;
            top: -35px;
            right: -30px;
            color: white;
            cursor: pointer;
            svg {
                width: 15px;
                height: 15px;
                path {
                    fill: white;
                }
            }
        }

        ::v-deep img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }


        .cs-popup-content {
            height: 100%;
            width: 100%;
            overflow-y: auto;
            span {
                height: 100%;
                width: 100%;
                display: block;
                embed {
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }
}

</style>
